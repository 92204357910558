import React, { Suspense } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation } from 'react-router-dom';
import Loading from './UI/Loading/Loading';

const Use = React.lazy(() => import('../pages/Use/Use'));
const PortofolioHome = React.lazy(() => import('../pages/PortofolioHome/PortofolioHome'));
const Home = React.lazy(() => import('../components/Home/Home'));

function AnimatedRoutes() {
    const location = useLocation();
  return (
      <AnimatePresence>
        <Suspense fallback={<Loading />} >
    <Routes location={location} key={location.pathname}>
    <Route exact path="/" element={<Home/>}/>
    <Route exact path={"/portofolio"} element={<PortofolioHome/>}/>
    <Route exact path={"/uses"} element={<Use/>}/>
  </Routes>
  </Suspense>
  </AnimatePresence>
  )
}

export default AnimatedRoutes